<template>
  <div class="max-w-full bg-gray-200 p-4 rounded overflow-auto">
    <div class="max-w-6xl mx-auto bg-white p-2">
      <div class="w-full bg-green-500 p-2 rounded-md">
        <label for="" class="text-2xl text-white">Appointment List</label>
      </div>
      <div
        @click="$router.go(-1)"
        class="text-brand rounded-md py-1.5 px-2 cursor-pointer w-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
      </div>
      <div class="flex justify-between items-center">
        <div
          v-if="ability.includes('Appointment Create') || ability.includes('*')"
          class="text-left py-2"
        >
          <router-link
            :to="{
              name: 'adminAppointmentCreate',
              params: { schedule_id: scheduleId },
            }"
            class="bg-blue-500 text-white rounded-md p-2"
            >Create Appointment</router-link
          >
        </div>
        <div>
          <span
            class="text-white p-2 bg-blue-500 rounded-md cursor-pointer"
            @click="historyModel = !historyModel"
          >
            Requested Mentor
          </span>
        </div>
      </div>

      <div>
        <!-- {{ appointments.expected_mentors_frequescy }} -->
        <div>
          <div
            class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50"
            v-if="historyModel"
          >
            <div class="relative mx-auto w-auto md:max-w-2xl">
              <div class="bg-white w-full rounded shadow-2xl flex flex-col">
                <div class="modal-body relative p-4 text-lg">
                  <div class="text-left p-4">
                    <div>
                      <table class="w-full table-auto">
                        <thead class="bg-white border-b">
                          <tr>
                            <th
                              scope="col"
                              class="text-md font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Mentor Name
                            </th>
                            <th
                              scope="col"
                              class="text-md font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Phone
                            </th>
                            <th
                              scope="col"
                              class="text-md font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Frequency
                            </th>
                            <th
                              scope="col"
                              class="text-md font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Mentor Details
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="border-b"
                            v-for="mentorRequest in appointments.expected_mentors_frequescy"
                            :key="mentorRequest.id"
                          >
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              {{ mentorRequest.name }}
                            </td>
                            <td
                              class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                            >
                              {{ mentorRequest.phone }}
                            </td>
                            <td
                              class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                            >
                              {{ mentorRequest.frequency }}
                            </td>
                            <td
                              class="text-sm text-gray-900 text-center font-light px-6 py-4 whitespace-nowrap"
                            >
                              <button
                                @click="
                                  (mentorDetails = !mentorDetails),
                                    mentorFind(mentorRequest.id)
                                "
                                class="p-2 bg-green-300 rounded-md"
                              >
                                Details
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <div
                          class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50"
                          v-if="mentorDetails"
                        >
                          <div class="relative mx-auto w-auto max-w-xl">
                            <div
                              class="bg-white w-full rounded shadow-2xl flex flex-col"
                            >
                              <div class="modal-body relative p-4 text-lg">
                                <div class="text-left p-4">
                                  <p>Name : {{ mentor_details.name }}</p>
                                  <p>Phone: {{ mentor_details.phone }}</p>
                                  <p>Email: {{ mentor_details.email }}</p>
                                  <p>
                                    Gender:
                                    {{
                                      mentor_details.gender == 1
                                        ? "Male"
                                        : "Female"
                                    }}
                                  </p>
                                  <p>BMDC: {{ mentor_details.bmdc }}</p>
                                  <p>
                                    Medical College:
                                    {{ mentor_details.medical }}
                                  </p>
                                </div>
                              </div>
                              <div
                                class="modal-footer text-center p-2 border-t border-gray-200 rounded-b-md"
                              >
                                <button
                                  type="button"
                                  class="inline-block px-6 py-2 bg-brand text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                  @click="mentorDetails = false"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="absolute inset-0 z-40 opacity-25 bg-gray-400"
                          v-if="mentorDetails"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-footer text-center p-2 border-gray-200 rounded-b-md"
                >
                  <Btn type="button" @click="historyModel = false"> Close </Btn>
                </div>
              </div>
            </div>
          </div>
          <div
            class="absolute inset-0 z-40 opacity-25 bg-gray-400"
            v-if="historyModel"
          ></div>
        </div>
      </div>
      <table
        class="w-full mx-auto"
        v-if="ability.includes('Appointment List') || ability.includes('*')"
      >
        <thead class="border">
          <tr>
            <th class="border-r p-1">SL</th>
            <th class="border-r p-1">Patient Name</th>
            <th class="border-r p-1">Serial</th>
            <th class="border-r p-1">Type</th>
            <th class="border-r b-1">Question</th>
            <th class="border-r p-1">Payable</th>
            <!-- <th class="border-r p-2">Appointment status</th> -->
            <th class="border-r p-1">Requested Mentor</th>
            <th class="border-r p-1">Mentor Assign</th>
            <th class="border-r p-1">Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody class="border">
          <tr
            v-for="(appointment, index) in appointments.data"
            :key="index"
            class="hover:bg-gray-100"
          >
            <td class="border-r border-b p-1">{{ appointment.id }}</td>
            <td class="border-r border-b p-1">{{ appointment.patientName }}</td>
            <td class="border-r border-b p-1">{{ appointment.serial }}</td>
            <td class="border-r border-b p-1">{{ appointment.type }}</td>
            <td
              class="border-r border-b p-1 print:max-w-full"
              style="width: 150px"
            >
              <router-link
                :to="{
                  name: 'questionPrint',
                  params: { appointmentId: appointment.id },
                }"
                class="bg-brand text-white py-2 p-2 rounded-md hover:shadow-md"
                >Question List</router-link
              >
            </td>
            <td class="border-r border-b p-2">{{ appointment.payable }}</td>
            <!-- <td class="border-r border-b p-2">
                            status
                            <div
                            class="cursor-pointer bg-white text-brand overflow-hidden relative text-center p-2 shadow rounded-md hover:shadow-lg">
                                <input id="default-checkbox1" type="checkbox" @click="status(appointment.id)" class="absolute -left-full">
                                <label for="default-checkbox1" class="ml-2 text-sm font-medium text-gray-900">confirm</label>
                            </div>
                            <div
                            class="cursor-pointer bg-yellow-500 text-brand overflow-hidden relative text-center p-2 shadow rounded-md hover:shadow-lg">
                                <input :id="`default-checkbox__${appointment.id}`" type="checkbox" v-model="status[appointment.id]" class="absolute -left-full">
                                <label :for="`default-checkbox__${appointment.id}`" class="ml-2 text-sm font-medium text-gray-900 cursor-pointer">Pending</label>
                            </div>

                        </td> -->
            <td class="border-r border-b p-2">
              {{ appointment.requested_mentor }}
            </td>
            <td class="border-r border-b p-2">
              <span>{{ appointment.assigned_mentor.name }}</span>
              <div v-if="appointment.assigned_mentor">
                <button
                  class="text-green-500"
                  @click="
                    mentorAssign = appointment.id;
                    mentor_id = appointment.assigned_mentor_id;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </button>

                <div>
                  <div
                    class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50"
                    v-if="mentorAssign == appointment.id"
                  >
                    <div class="relative mx-auto w-auto max-w-2xl">
                      <div class="bg-white rounded shadow-2xl flex flex-col">
                        <div class="text-right text-red-600 rounded-b-md">
                          <button
                            type="button"
                            class="p-2"
                            @click="mentorAssign = false"
                          >
                            &#10006;
                          </button>
                        </div>

                        <div class="modal-body relative text-lg">
                          <div class="text-left p-2">
                            <label for="">Mentor</label>
                            <Select
                              class="w-full border py-3 bg-white outline-none p-2"
                              v-model="mentor_id"
                            >
                              <option value="">Select User</option>
                              <option
                                :value="user.id"
                                v-for="user in appointments.mentors[
                                  appointment.type_id
                                ]"
                                :key="user.id"
                              >
                                {{ user.name ? user.name : "" }}
                              </option>
                            </Select>
                          </div>
                        </div>
                        <div
                          class="modal-footer text-center p-2 border-t border-gray-200 rounded-b-md"
                        >
                          <btn
                            type="button"
                            @click="
                              mentorAssignUpdate(appointment.id, mentor_id)
                            "
                          >
                            Submit
                          </btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <button
                  @click="mentorAssign = appointment.id"
                  title="Assign"
                  class="text-blue-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </button>

                <div>
                  <div
                    class="fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-50"
                    v-if="mentorAssign == appointment.id"
                  >
                    <div class="relative mx-auto w-auto max-w-2xl">
                      <div class="bg-white rounded shadow-2xl flex flex-col">
                        <div class="text-right text-red-600 rounded-b-md">
                          <button
                            type="button"
                            class="p-2"
                            @click="mentorAssign = false"
                          >
                            &#10006;
                          </button>
                        </div>
                        <div class="modal-body relative text-lg">
                          <div class="text-left p-2">
                            <label for="">Mentor</label>
                            <Select
                              class="w-full border py-3 bg-white outline-none p-2"
                              v-model="mentor_id"
                            >
                              <option value="">Select User</option>
                              <option
                                :value="user.id"
                                v-for="user in appointments.mentors[
                                  appointment.type_id
                                ]"
                                :key="user.id"
                              >
                                {{ user.name ? user.name : "" }}
                              </option>
                            </Select>
                          </div>
                        </div>
                        <div
                          class="modal-footer text-center p-2 border-t border-gray-200 rounded-b-md"
                        >
                          <button
                            type="button"
                            class="inline-block px-6 py-2 bg-brand text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                            @click="mentorAssignSubmit(appointment.id)"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="border-r border-b p-2">
              <div
                v-if="appointment.mentor"
                class="text-brand flex justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div v-if="!appointment.mentor && !status">Pending</div>
              <div v-if="status && !appointment.mentor" class="text-red-500">
                Missed
              </div>
            </td>

            <td class="border-b p-2">
              <div class="flex gap-2 items-center justify-center">
                <router-link
                  v-if="
                    ability.includes('Appointment Edit') ||
                    ability.includes('*')
                  "
                  :to="{
                    name: 'adminAppointmentEdit',
                    params: { appointmentId: appointment.id },
                  }"
                  title="Edit"
                  class="bg-blue-500 text-white text-sm p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </router-link>
                <!-- <router-link v-if="ability.includes('Appointment Delete') || ability.includes('*')" to="/admin/schedules" title="Cancle" class="bg-red-500 text-white text-sm p-1 rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </router-link> -->
                <router-link
                  :to="{
                    name: 'adminFeedbackIndex',
                    params: { appointmentId: appointment.id },
                  }"
                  title="FeedBack"
                  class="bg-blue-500 text-white text-sm p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 rotate-45"
                    fill="none"
                    viewBox="0 0 28 28"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    />
                  </svg>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Select from "../../../../components/select.vue";
import Input from "../../../../components/input-text.vue";
import Textarea from "../../../../components/input-text-area.vue";
import Btn from "../../../../components/btn.vue";
import axios from "../../../../store/axios";
import NavLink from "../../layouts/nav-link.vue";
export default {
  components: {
    Select,
    Input,
    Textarea,
    Btn,
    NavLink,
  },
  data() {
    return {
      historyModel: "",
      mentorDetails: "",
      mentor_details: "",
      mentorAssign: "",
      mentor_id: "",
      modal: "",
      status: "",
    };
  },

  created() {
    this.$store.dispatch("adminAppointment/getAppointments", {
      scheduleId: this.scheduleId,
    });
  },

  // watch: {
  //     '$store.state.adminAppointment.appointments'(element){
  //         console.log('asd', element.data[2].assigned_mentor_id);
  //         let mentor = this.mentor_id

  //     }
  // },
  computed: {
    appointments() {
      return this.$store.state.adminAppointment.appointments;
    },
    scheduleId() {
      return this.$route.params.scheduleId;
    },
    ability() {
      return this.$store.state.auth.abilities || "";
    },
    status() {
      return this.appointments.schedule < this.now;
    },
    now() {
      let date = new Date();
      let month = "" + (date.getMonth() + 1);
      let day = "" + date.getDate();
      let year = date.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },
  },
  methods: {
    mentorFind(element) {
      this.mentor_details = Object.values(
        this.appointments.expected_mentors_frequescy
      ).find((obj) => {
        return obj.id == element;
      });
    },
    async mentorAssignSubmit(element) {
      if (this.mentor_id && element) {
        let mentorAssign = await axios()
          .post("/api/mentor-assign", {
            appointment_id: element,
            mentor_id: this.mentor_id,
          })
          .then(() => {
            this.$store.dispatch("adminAppointment/getAppointments", {
              scheduleId: this.scheduleId,
            });
            this.mentorAssign = false;
          });
      } else {
        alert("Please Input Proper value");
      }
      console.log(mentorAssign);
    },
    async mentorAssignUpdate(element, id) {
      console.log(element, id);
      if (this.mentor_id && element) {
        let mentorAssign = await axios()
          .put(`/api/mentor-assign/${id}`, {
            appointment_id: element,
            mentor_id: this.mentor_id,
          })
          .then(() => {
            this.$store.dispatch("adminAppointment/getAppointments", {
              scheduleId: this.scheduleId,
            });
            this.mentorAssign = false;
          });
      } else {
        alert("Please Input Proper value");
      }
      // console.log(mentorAssign);
    },
    status(id) {
      console.log(id);
    },
  },
};
</script>
